
import { defineComponent, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import apiAlert from "@/core/services/Notification";
import { Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import buttonHandle from "@/shared.js";

export default defineComponent({
    name: "administration-notification",
    components: { Field },
    data: function () {
        return {
            notification: {
                id: null,
                name: "",
                description: "",
            },
            notifications: [],
        };
    },
    methods: {
        getNotifications() {
            apiAlert.getNotifications().then((response) => {
                this.notifications = response.data['notifications'];
            });
        },
        addNotification() {
            buttonHandle.handleWaitingButton(this.submitButton);
            if (this.notification.id) {
                apiAlert.updateNotification(this.notification.id, this.notification).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                });
            } else {
                apiAlert.addNotification(this.notification).then(() => {
                    buttonHandle.handleValidatedButton(this.submitButton);
                    setTimeout(() => {
                        location.reload();
                    }, 500);
                });
            }
        },
        initEdit(alert) {
            this.notification = {
                id: alert.pk,
                name: alert.name,
                description: alert.description,
            };
        },
        initAdd() {
            this.notification = {
                id: null,
                name: "",
                description: "",
            };
        },
    },
    setup() {
        const { t } = useI18n();
        setCurrentPageBreadcrumbs("Alertes", []);
        const submitButton = ref<HTMLElement | null>(null);

        return { t, submitButton };
    },
    watch: {
        "$i18n.locale": function () {
            setCurrentPageBreadcrumbs("Alertes", []);
        },
    },
    created() {
        this.getNotifications();
    },
});
